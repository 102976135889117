import Auth from "@aws-amplify/auth"
import to from "await-to-js"

export const getUser = async () => {
  const [err, user] = await to(Auth.currentAuthenticatedUser())

  if (err) {
    return new Error(err.message)
  }

  return user
}
