<template>
  <div class="login">
    <sign-in
      v-if="displayMap.showSignIn"
      :signInConfig="options.signInConfig"
      :usernameAttributes="options.usernameAttributes"
    ></sign-in>
    <!--    <sign-up
      v-if="displayMap.showSignUp"
      :signUpConfig="options.signUpConfig"
      :usernameAttributes="options.usernameAttributes"
    ></sign-up>
    <forgot-password
      v-if="displayMap.showForgotPassword"
      :forgotPasswordConfig="options.forgotPasswordConfig"
      :usernameAttributes="options.usernameAttributes"
    ></forgot-password>
    <confirm-sign-up
      v-if="displayMap.showConfirmSignUp"
      v-bind:confirmSignUpConfig="options.confirmSignUpConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></confirm-sign-up>
    <confirm-sign-in
      v-if="displayMap.showConfirmSignIn"
      v-bind:confirmSignInConfig="options.confirmSignInConfig"
    ></confirm-sign-in>
    <require-new-password
      v-if="displayMap.requireNewPassword"
      v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
    ></require-new-password>
    <set-mfa
      v-if="displayMap.showMfa"
      v-bind:mfaConfig="options.mfaConfig"
    ></set-mfa>-->
  </div>
</template>

<script>
import {
  //ConfirmSignIn,
  //ConfirmSignUp,
  //ForgotPassword,
  //RequireNewPassword,
  SignIn
  //SignUp
} from "."
import AmplifyEventBus from "../../auth/AmplifyEventBus"
import { getUser } from "../../auth/getUser"

export default {
  name: "Authenticator",
  props: ["authConfig"],
  components: {
    //ConfirmSignIn,
    //ConfirmSignUp,
    //ForgotPassword,
    //RequireNewPassword,
    SignIn
    //SignUp
  },
  data() {
    return {
      user: {
        username: null
      },
      displayMap: {},
      error: "",
      logger: {}
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {},
        usernameAttributes: "username"
      }
      return Object.assign(defaults, this.authConfig || {})
    }
  },
  async mounted() {
    AmplifyEventBus.$on("localUser", user => {
      this.user = user
      this.options.signInConfig.username = this.user.username
      this.options.confirmSignInConfig.user = this.user
      this.options.confirmSignUpConfig.username = this.user.username
      this.options.requireNewPasswordConfig.user = this.user
    })
    AmplifyEventBus.$on("authState", data => {
      this.displayMap = this.updateDisplayMap(data)
    })
    getUser()
      .then(val => {
        if (val instanceof Error) {
          return (this.displayMap = this.updateDisplayMap("signedOut"))
        }
        this.user = val
        return (this.displayMap = this.updateDisplayMap("signedIn"))
      })
      .catch(e => this.setError(e))
  },
  methods: {
    updateDisplayMap(state) {
      return {
        showSignIn: state === "signedOut" || state === "signIn",
        showSignUp: state === "signUp",
        showConfirmSignUp: state === "confirmSignUp",
        showConfirmSignIn: state === "confirmSignIn",
        showForgotPassword: state === "forgotPassword",
        showSignOut: state === "signedIn",
        showMfa: state === "setMfa",
        requireNewPassword: state === "requireNewPassword"
      }
    },
    setError: function(e) {
      // @todo Handle translations for Cognito server side errors
      this.error = this.$t(e.message || e)
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background: white;
}
</style>
