<template>
  <div class="container container-table">
    <div class="row vertical-10p">
      <div class="container">
        <div class="content text-center col-sm-6 col-sm-offset-3">
          <h1>404</h1>
          <h4>Page not found</h4>
          <router-link class="gotohome" :to="{ name: 'overview' }">
            Return to Homepage
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "not_found"
}
</script>

<style lang="scss" scoped>
h1 {
  color: orangered;
}
h4 {
  color: white;
}
.content {
  margin-top: 200px;
}
.gotohome {
  color: cyan;
  font-size: 1.8rem;

  &:hover,
  &:focus {
    color: cyan;
  }
}
</style>
