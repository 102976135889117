/* ============
 * Vue ACL
 * ============
 *
 * This plugin aims to control the layout of the system
 * and block access to certain routes of the vue-router,
 * that according to the current active permission on the system.
 *
 * based on https://github.com/leonardovilarinho/vue-acl
 * but use vue-store instead
 */

import Vue from "vue"
import router from "../router"
import store from "../store"

class Acl {
  init(router) {
    this.router = router
  }

  getPermissions() {
    const permissions = store.state.account.permissions
    return Array.isArray(permissions) ? permissions : [permissions]
  }

  check(permission) {
    if (typeof permission !== "undefined") {
      if (permission === "") {
        return true
      }

      const permissions =
        permission.indexOf("|") !== -1 ? permission.split("|") : [permission]
      const statePermissions = this.getPermissions()

      return (
        permissions.find(permission => {
          const needed =
            permission.indexOf("&") !== -1 ? permission.split("&") : permission

          if (Array.isArray(needed)) {
            return needed.every(need => {
              return statePermissions.indexOf(need) !== -1
            })
          }

          return statePermissions.indexOf(needed) !== -1
        }) !== undefined
      )
    }
    return false
  }

  set router(router) {
    router.beforeEach((to, from, next) => {
      const fail = to.meta.fail || "/"
      if (typeof to.meta.permission === "undefined") {
        return next(fail)
      } else {
        if (!this.check(to.meta.permission)) {
          Vue.$log.error("Access denied")
          return next(fail)
        }
        next()
      }
    })
  }
}

let acl = new Acl()

Acl.install = function(Vue, { router, init }) {
  acl.init(router, init)

  Vue.prototype.$can = function(permission) {
    return acl.check(permission)
  }
}

Vue.use(Acl, { router: router, init: "any" })
