import { guid } from "../../helpers/guid"

export default {
  setPermissions(state, permissions) {
    if (Array.isArray(permissions)) {
      state.permissions = ["any", ...permissions]
    } else if (permissions.indexOf("&") !== -1) {
      state.permissions = ["any", ...permissions.split("&")]
    } else {
      state.permissions = ["any", permissions]
    }
  },

  addPermission(state, permission) {
    if (!state.permissions.includes(permission)) {
      state.permissions.push(permission)
    }
  },

  setUser(state, user) {
    state.user = user
  },

  setSessionId(state) {
    if (!state.sessionId) {
      state.sessionId = guid()
    }
  },

  setExpiredAt(state, eat) {
    state.eat = eat
  },

  logout(state) {
    state.user = null
    state.permissions = ["any"]
    state.sessionId = null
  }
}
