import Vue from "vue"

class ApiService {
  /**
   * The constructor of the ApiService.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint
    this.parameters = parameters
  }

  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {ApiService} The instance of the proxy.
   */
  setParameters(parameters) {
    Object.keys(parameters).forEach(key => {
      this.parameters[key] = parameters[key]
    })

    return this
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {ApiService} The instance of the proxy.
   */
  setParameter(parameter, value) {
    this.parameters[parameter] = value

    return this
  }

  /**
   * Method used to remove all the parameters.
   *
   * @returns {ApiService} The instance of the proxy.
   */
  removeParameters() {
    this.parameters = {}

    return this
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {ApiService} The instance of the proxy.
   */
  removeParameter(parameter) {
    delete this.parameters[parameter]

    return this
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  submit(requestType = "get", url = this.endpoint, data = {}) {
    return Vue.$http[requestType](url + this.getParameterString(), data)
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString() {
    const keys = Object.keys(this.parameters)

    const parameterStrings = keys
      .filter(key => !!this.parameters[key])
      .map(key => `${key}=${this.parameters[key]}`)

    return parameterStrings.length === 0 ? "" : `?${parameterStrings.join("&")}`
  }
}

export default ApiService
