import Vue from "vue"
import { toCents, toDollars } from "../helpers/currency"

const formatPrice = function(price, locale = "en-CA") {
  price = price / 100

  return price.toLocaleString(locale, { style: "currency", currency: "CAD" })
}

Vue.filter("toCents", toCents)
Vue.filter("toCents", toDollars)
Vue.filter("formatPrice", formatPrice)
