import ApigDashboardService from "../../services/apig/ApigDashboardService"
import ApigRefundService from "../../services/apig/ApigRefundService"

const dashboardApi = new ApigDashboardService()
const refundApi = new ApigRefundService()

export default {
  async loadDataByEmail({ commit }, email) {
    const orders = await dashboardApi.getUserDataByEmail(email)
    commit("setOrders", orders)
  },

  async loadDataByOrderId({ commit }, orderId) {
    const orders = await dashboardApi.getUserDataByOrderId(orderId)
    commit("setOrders", orders)
  },

  processRefund(params, refund) {
    return refundApi.processRefund(refund)
  },

  /**
   * Validate calculated refund amount using refund validator API
   */
  validateRefund(params, refund) {
    return refundApi.validateRefund(refund)
  }
}
