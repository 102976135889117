import Vue from "vue"
import Amplify, { Logger } from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"

import authConfig from "../authConfig"

Amplify.configure(authConfig)

Vue.prototype.$Amplify = {
  Auth,
  Logger
}
