import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

const state = () => ({
  lang: "fr",
  breadcrumbs: []
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
