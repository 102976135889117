<template>
  <div>
    <!-- BEGIN HEADER -->
    <div class="page-header navbar navbar-fixed-top">
      <!-- BEGIN HEADER INNER -->
      <div class="page-header-inner ">
        <!-- BEGIN LOGO -->
        <div class="page-logo">
          <router-link to="start-screen">
            <img
              src="https://tuango3.s3.us-east-2.amazonaws.com/assets/logo-tuango-orange.svg"
              alt="logo"
            />
          </router-link>
          <div class="menu-toggler sidebar-toggler" @click="toggleSidebar()">
            <span></span>
          </div>
        </div>
        <!-- END LOGO -->

        <!-- BEGIN RESPONSIVE MENU TOGGLER -->
        <a
          href="javascript:;"
          class="menu-toggler responsive-toggler"
          @click="toggleSidebar()"
        >
          <span></span>
        </a>
        <!-- END RESPONSIVE MENU TOGGLER -->

        <!-- BEGIN TOP NAVIGATION MENU -->
        <div class="top-menu">
          <ul class="nav navbar-nav pull-right">
            <!--            <lang-switcher-->
            <!--              @toggle="toggleDropdown($event, 'langSwitcher')"-->
            <!--              v-click-outside="hideDropdowns"-->
            <!--              :isOpen="activeDropdown == 'langSwitcher'"-->
            <!--            ></lang-switcher>-->
            <user-login
              @toggle="toggleDropdown($event, 'login')"
              v-click-outside="hideDropdowns"
              :isOpen="activeDropdown == 'login'"
            ></user-login>
          </ul>
        </div>
        <!-- END TOP NAVIGATION MENU -->
      </div>
      <!-- END HEADER INNER -->
    </div>
    <!-- END HEADER -->
    <!-- BEGIN HEADER & CONTENT DIVIDER -->
    <div class="clearfix"></div>
    <!-- END HEADER & CONTENT DIVIDER -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"

// import LangSwitcher from "./LangSwitcher"
import UserLogin from "./UserLogin"

const { mapActions } = createNamespacedHelpers("sidebar")

export default {
  components: {
    // LangSwitcher,
    UserLogin
  },
  data() {
    return {
      activeDropdown: null
    }
  },
  methods: {
    ...mapActions(["toggleSidebar"]),
    toggleDropdown(event, dropdownName) {
      this.activeDropdown = event.isOpen ? dropdownName : null
    },
    hideDropdowns() {
      this.activeDropdown = null
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  > ul.dropdown-menu {
    display: block;
  }
}

.page-logo img {
  height: 42px;
  margin-top: 4px;
}
</style>
