<template>
  <div class="signin-form">
    <div class="text-center">
      <img
        src="https://tuango3.s3.us-east-2.amazonaws.com/assets/logo-tuango-orange.svg"
        alt=""
      />
      <h3>{{ options.header }}</h3>
    </div>
    <div>
      <!--      <username-field-->
      <!--        :usernameAttributes="usernameAttributes"-->
      <!--        @username-field-changed="usernameFieldChanged"-->
      <!--      >-->
      <!--      </username-field>-->
      <!--      <div :class="amplifyUI.formField">-->
      <!--        <div :class="amplifyUI.inputLabel">{{ $t("AUTH.PASSWORD") }} *</div>-->
      <!--        <input-->
      <!--          :class="amplifyUI.input"-->
      <!--          v-model="password"-->
      <!--          type="password"-->
      <!--          :placeholder="$t('AUTH.PASSWORD_PLACEHOLDER')"-->
      <!--          @keyup.enter="signIn"-->
      <!--          :data-test="auth.signIn.passwordInput"-->
      <!--        />-->
      <!--        <div :class="amplifyUI.hint">-->
      <!--          {{ $t("AUTH.FORGET_PASSWORD") }}-->
      <!--          <a-->
      <!--            :class="amplifyUI.a"-->
      <!--            @click="forgot"-->
      <!--            :data-test="auth.signIn.forgotPasswordLink"-->
      <!--          >-->
      <!--            {{ $t("AUTH.RESET_PASSWORD") }}-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--<div
      :class="amplifyUI.sectionFooter"
      :data-test="auth.signIn.footerSection"
    >
      <span :class="amplifyUI.sectionFooterPrimaryContent">
        <button
          :class="amplifyUI.button"
          @click="signIn"
          :data-test="auth.signIn.signInButton"
        >
          {{ $t("AUTH.SIGN_IN") }}
        </button>
      </span>
      <span
        :class="amplifyUI.sectionFooterSecondaryContent"
        v-if="options.isSignUpDisplayed"
      >
        {{ $t("AUTH.NO_ACCOUNT") }}
        <a
          :class="amplifyUI.a"
          @click="signUp"
          :data-test="auth.signIn.createAccountLink"
        >
          {{ $t("AUTH.CREATE_ACCOUNT") }}
        </a>
      </span>
    </div>
    <div class="error" v-if="error" :data-test="auth.signIn.signInError">
      {{ error }}
    </div>-->

    <div>
      <br /><br />
      <button class="social-login-btn" @click="federatedProviderAuth('Google')">
        <i class="fab fa-google"></i>&nbsp;&nbsp;{{ $t("AUTH.GOOGLE_LOGIN") }}
      </button>

      <!--<button
        :id="amplifyUI.facebookSignInButton"
        class="social-login-btn"
        @click="federatedProviderAuth('Facebook')"
        :data-test="auth.signIn.signInButton"
      >
        <i class="fab fa-facebook-f"></i>
      </button>-->
    </div>
  </div>
</template>

<script>
import to from "await-to-js"

import AmplifyEventBus from "../../auth/AmplifyEventBus"
import { labelMap } from "./common"
import { auth } from "../../auth/data-test-attributes"
// import UsernameField from "./UsernameField"

export default {
  name: "SignIn",
  props: ["signInConfig", "usernameAttributes"],
  components: {
    // UsernameField
  },
  data() {
    return {
      password: "",
      error: "",
      auth,
      logger: {},
      signInUsername: "",
      labelMap
    }
  },
  computed: {
    options() {
      const defaults = {
        header: this.$t("AUTH.SIGN_IN_HEADER"),
        username: "",
        isSignUpDisplayed: true
      }
      return Object.assign(defaults, this.signInConfig || {})
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
  },
  methods: {
    async signIn() {
      const [err, data] = await to(
        this.$Amplify.Auth.signIn(this.signInUsername, this.password)
      )

      if (err) {
        if (err.code && err.code === "UserNotConfirmedException") {
          AmplifyEventBus.$emit("localUser", {
            username: this.signInUsername
          })
          return AmplifyEventBus.$emit("authState", "confirmSignUp")
        } else {
          return this.setError(err)
        }
      }

      this.logger.info("sign in success")
      if (
        data.challengeName === "SMS_MFA" ||
        data.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        AmplifyEventBus.$emit("localUser", data)
        return AmplifyEventBus.$emit("authState", "confirmSignIn")
      } else if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
        AmplifyEventBus.$emit("localUser", data)
        return AmplifyEventBus.$emit("authState", "requireNewPassword")
      } else if (data.challengeName === "MFA_SETUP") {
        AmplifyEventBus.$emit("localUser", data)
        return AmplifyEventBus.$emit("authState", "setMfa")
      } else if (
        data.challengeName === "CUSTOM_CHALLENGE" &&
        data.challengeParam &&
        data.challengeParam.trigger === "true"
      ) {
        AmplifyEventBus.$emit("localUser", data)
        return AmplifyEventBus.$emit("authState", "customConfirmSignIn")
      } else {
        return AmplifyEventBus.$emit("authState", "signedIn")
      }
    },
    forgot() {
      AmplifyEventBus.$emit("authState", "forgotPassword")
    },
    signUp() {
      AmplifyEventBus.$emit("authState", "signUp")
    },
    setError(e) {
      this.error = this.$t(e.message || e)
      this.logger.error(this.error)
    },
    usernameFieldChanged(data) {
      const { usernameField, username, email, phoneNumber } = data
      switch (usernameField) {
        case "username":
          this.signInUsername = username
          break
        case "email":
          this.signInUsername = email
          break
        case "phone_number":
          this.signInUsername = phoneNumber
          break
        default:
          break
      }
    },
    federatedProviderAuth(provider) {
      this.$Amplify.Auth.federatedSignIn({ provider })
    }
  }
}
</script>

<style lang="scss" scoped>
.signin-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  .social-login-btn {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #4285f4;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: lighten(#4285f4, 5%);
    }
  }
}
</style>
