import { createNamespacedHelpers } from "vuex"

const { mapMutations } = createNamespacedHelpers("app")

export default {
  methods: {
    ...mapMutations(["setBreadcrumbs"])
  },
  created() {
    if (this.breadcrumbs) {
      this.setBreadcrumbs(this.breadcrumbs)
    }
  }
}
