import Vue from "vue"
import moment from "moment"

const tsToFormattedDate = ts => {
  return moment.unix(ts / 1000).format("YYYY-MM-DD HH:mm")
}

const phpDateToJs = phpDate => {
  const dateFormatted = moment(phpDate).format("DD-MM-YYYY hh:mm:ss")
  return dateFormatted !== "Invalid date" ? dateFormatted : ""
}

const phpDateToFormat = (phpDate, format = "YYYY-MM-DD") => {
  const dateFormatted = moment(phpDate).format(format)
  return dateFormatted !== "Invalid date" ? dateFormatted : ""
}

Vue.filter("formatTs", tsToFormattedDate)
Vue.filter("formatPhpDate", phpDateToJs)
Vue.filter("phpDateToFormat", phpDateToFormat)
