/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from "vue"
import Auth from "@aws-amplify/auth"
import Axios from "axios"
import store from "../store"
import { to } from "await-to-js"

Axios.defaults.baseURL = process.env.VUE_APP_LOD_DW_API
Axios.defaults.headers.common.Accept = "application/json"
Axios.interceptors.request.use(
  async config => {
    const now = +new Date()

    if (store.state.account.eat - now < 5000) {
      // will refresh token if necessary
      const [err, session] = await to(Auth.currentSession())

      if (err) {
        await store.dispatch("account/logout")
      }

      // Update session
      await store.dispatch("account/refreshSession", session)
      const token = session.getIdToken().getJwtToken()
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
Axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      await store.dispatch("account/logout")
    }

    return Promise.reject(error)
  }
)

// Bind Axios to Vue.
Vue.$http = Axios
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return Axios
  }
})
