<template>
  <default>
    <transition name="el-fade-in" mode="out-in">
      <router-view></router-view>
    </transition>
  </default>
</template>

<script>
import { createNamespacedHelpers } from "vuex"

// @ is an alias to /src
import Default from "../layouts/Default"

const { mapState: mapAccountState } = createNamespacedHelpers("account")

export default {
  name: "home",
  components: {
    Default
  },
  computed: {
    ...mapAccountState(["sessionId"])
  }
}
</script>
