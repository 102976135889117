import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

const state = () => ({
  permissions: ["any"],
  sessionId: null,
  user: null,
  eat: Infinity // token exp in ms
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
