import ApiService from "../ApiService"

class ApigRefundService extends ApiService {
  /**
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${process.env.VUE_APP_APIG_API}lod-refund`, parameters)
  }

  /**
   * @param {Refund} refund
   * @return {Promise<any | never>}
   */
  processRefund(refund) {
    // Prepare request to match API schema
    refund.ORDER_ITEMS = refund.REFUND_ITEMS
    delete refund.REFUND_ITEMS
    return this.removeParameters()
      .submit("post", `${this.endpoint}/refund`, {
        ...refund
      })
      .then(res => {
        return res.data
      })
  }

  /**
   * @param {Refund} refund
   * @returns {Promise<boolean>}
   */
  validateRefund(refund) {
    return this.removeParameters()
      .submit("post", `${this.endpoint}/refund/simulate`, {
        orderId: refund.ORDER_ID,
        items: refund.REFUND_ITEMS.filter(item => item.QTY > 0).map(item => {
          return {
            orderItemId: item.ORDER_ITEM_ID,
            refundQty: item.QTY
          }
        })
      })
      .then(res => {
        const expectedRefundAmount = res.data.totalRefundAmount
        const calculatedRefundAmount = refund.CASH_AMOUNT
        console.log(`Expected refund amount: ${expectedRefundAmount}`)
        console.log(`Refund cash amount: ${refund.CASH_AMOUNT}`)
        console.log(`Refund credit amount: ${refund.CREDIT_AMOUNT}`)
        console.log(`Calculated refund amount: ${calculatedRefundAmount}`)
        console.log(
          `Difference: ${Math.abs(
            expectedRefundAmount - calculatedRefundAmount
          )}`
        )

        // Allow a difference of 1 cent between the expected and calculated refund amounts
        return Math.abs(expectedRefundAmount - calculatedRefundAmount) <= 1
      })
  }

  getRefundRequests() {
    return this.removeParameters()
      .submit("get", `${this.endpoint}/refundRequests`)
      .then(res => {
        return res.data
      })
  }

  deleteRefundRequest(refundRequestId) {
    return this.removeParameters()
      .submit("delete", `${this.endpoint}/refundRequests/${refundRequestId}`)
      .then(res => {
        return res.data
      })
  }
}

export default ApigRefundService
