<template>
  <li class="nav-item" :class="{ active: $route.name === routeName }">
    <router-link
      @click.native.stop=""
      :to="{ name: routeName, params: allRouteParams }"
      class="nav-link"
    >
      <i :class="[$route.name === routeName ? `${icon} font-green` : icon]"></i
      >&nbsp;<span class="title">{{ title }}</span>
      <!-- <span class="selected"></span> -->
      <slot></slot>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true
    },
    routeParams: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "icon-home"
    }
  },
  computed: {
    allRouteParams() {
      return {
        locale: this.$i18n.locale,
        ...this.routeParams
      }
    }
  }
}
</script>
