import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import app from "./app"
import account from "./account"
import sidebar from "./sidebar"
import customer from "./customer"
import refundHistoryTool from "./refundHistoryTool"

export const VUEX_STORAGE_KEY = "tuango_dashboard_data"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: VUEX_STORAGE_KEY,
  storage: window.localStorage
})

const store = new Vuex.Store({
  modules: {
    app,
    account,
    sidebar,
    customer,
    refundHistoryTool
  },
  plugins: [vuexLocal.plugin]
})

export default store
