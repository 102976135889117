import { state as getDefaultState } from "./state"

export default {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setOrders(state, orders) {
    state.orders = orders
  },
  setCustomerInfos(state, customerInfos) {
    state.customerInfos = customerInfos
  },
  refreshState(state) {
    state.refreshTs = +new Date()
  }
}
