/* ============
 * Vue2Storage
 * ============
 *
 * Browser storage for Vue.js app
 *
 * https://yarkovaleksei.github.io/vue2-storage/en/
 */

import Vue from "vue"
import Vue2Storage from "vue2-storage"

Vue.use(Vue2Storage, {
  prefix: "mcenter_",
  driver: "local",
  ttl: 60 * 60 * 24 * 1000
})
