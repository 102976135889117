<template>
  <li
    class="nav-item"
    :class="{ active: $route.meta.group === routeGroup, open: expanded }"
    @click.stop="expanded = !expanded"
  >
    <a href="javascript:" class="nav-link nav-toggle">
      <i :class="icon"></i> <span class="title">{{ title }}</span>
      <span class="selected"></span>
      <span class="arrow" :class="{ open: expanded }"> </span>
    </a>
    <slot :expanded="expanded"></slot>
  </li>
</template>

<script>
export default {
  name: "sidebar-item-group",
  props: {
    routeGroup: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "fas fa-home"
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  watch: {
    "$route.meta.group"(val) {
      this.expanded = val === this.routeGroup
    }
  }
}
</script>
