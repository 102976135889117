<template>
  <!-- BEGIN USER LOGIN DROPDOWN -->
  <li class="dropdown dropdown-user">
    <a
      href="javascript:;"
      class="dropdown-toggle"
      @click="$emit('toggle', { isOpen: !isOpen })"
    >
      <img alt="" class="img-circle" :src="compUser.picture || ''" />
      <span class="username username-hide-on-mobile">{{
        compUser.firstName || compUser.email || "guest"
      }}</span>
      <i class="fa fa-angle-down"></i>
    </a>
    <transition name="fade">
      <ul v-show="isOpen" class="dropdown-menu dropdown-menu-default">
        <li>
          <router-link v-if="!user" :to="{ name: 'login' }">
            <i class="fas fa-sign-in-alt"></i> Login
          </router-link>
          <a
            v-if="user"
            href="#"
            @click.prevent="
              logout()
              $emit('toggle', { isOpen: false })
            "
          >
            <i class="fas fa-sign-out-alt"></i> Logout
          </a>
        </li>
      </ul>
    </transition>
  </li>
  <!-- END USER LOGIN DROPDOWN -->
</template>

<script>
import { createNamespacedHelpers } from "vuex"

const { mapActions, mapState } = createNamespacedHelpers("account")

export default {
  props: {
    isOpen: {
      default: false
    }
  },
  computed: {
    ...mapState(["user"]),
    compUser() {
      const user = this.user || {}
      return {
        email: user.email,
        firstName: user.firstName,
        picture: user.picture
      }
    }
  },
  methods: {
    ...mapActions(["logout"])
  }
}
</script>
