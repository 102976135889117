export function guid() {
  const u =
    new Date().getTime().toString(16) +
    Math.random()
      .toString(16)
      .substring(2) +
    Math.random()
      .toString(16)
      .substring(2)
  return (
    u.substr(0, 8) +
    "-" +
    u.substr(8, 4) +
    "-" +
    u.substr(12, 4) +
    "-" +
    u.substr(16, 4) +
    "-" +
    u.substr(20, 12)
  )
}
