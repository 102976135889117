import Vue from "vue"

import { getUser } from "../../auth/getUser"
import AmplifyEventBus from "../../auth/AmplifyEventBus"
import Auth from "@aws-amplify/auth"

export default {
  async checkSession({ dispatch }) {
    const user = await getUser()
    if (user instanceof Error) {
      return dispatch("endSession")
    }
    AmplifyEventBus.$emit("authState", "signedIn")

    const session = user.getSignInUserSession()
    dispatch("refreshSession", session)
  },

  refreshSession({ commit }, session) {
    const token = session.getIdToken().getJwtToken()
    const userData = session.getIdToken().payload
    const groups = userData["cognito:groups"]

    commit("setUser", userData)
    commit("setExpiredAt", userData.exp * 1000)
    commit("setSessionId")

    Vue.$http.defaults.headers.common.Authorization = `Bearer ${token}`
    commit("setPermissions", groups)
  },

  async logout({ dispatch }) {
    await Auth.signOut()
    dispatch("endSession")
  },

  endSession({ commit }) {
    Vue.$http.defaults.headers.common.Authorization = ""
    commit("logout")
  }
}
