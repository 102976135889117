/* ============
 * Vue Media Query
 * ============
 *
 * Plugin adds methods for work with media query in vue
 *
 * https://github.com/AStaroverov/v-media-query
 */

import Vue from "vue"
import vMediaQuery from "v-media-query"

Vue.use(vMediaQuery)
