import Vue from "vue"

Vue.directive("click-outside", {
  bind(el, binding) {
    el.addEventListener("click", evt => {
      evt.stopPropagation()
    })
    document.body.addEventListener("click", binding.value)
  },

  unbind(el, binding) {
    el.removeEventListener("click", evt => {
      evt.stopPropagation()
    })
    document.body.removeEventListener("click", binding.value)
  }
})
