export function toCents(value) {
  value = (value + "").replace(/[^\d.-]/g, "")
  if (value && value.includes(".")) {
    value = value.substring(0, value.indexOf(".") + 3)
  }

  return value ? Math.round(parseFloat(value) * 100) : 0
}

export function toDollars(value) {
  value = (value + "").replace(/[^\d.-]/g, "")
  value = parseFloat(value)
  return value ? value / 100 : 0
}
