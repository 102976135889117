<template>
  <ul class="page-breadcrumb" v-if="breadcrumbs.length">
    <li v-for="(crumb, key) in breadcrumbs" :key="key">
      <router-link
        v-if="crumb.route"
        class="breadcrumb-item"
        :to="{ name: crumb.route }"
        >{{ crumb.text }}</router-link
      >
      <span v-else>{{ crumb.text }}</span
      ><i class="fas fa-circle" v-if="key !== breadcrumbs.length - 1"></i>
    </li>
  </ul>
</template>

<script>
import { createNamespacedHelpers } from "vuex"

const { mapState } = createNamespacedHelpers("app")

export default {
  name: "breadcrumbs",
  computed: {
    ...mapState(["breadcrumbs"])
  }
}
</script>

<style lang="scss" scoped>
.page-breadcrumb > li i {
  font-size: 9px;
  vertical-align: middle;
  margin: 0 5px;
}
</style>
