<template>
  <div class="login-page">
    <div>
      <authenticator
        :authConfig="authConfig"
        ref="authenticator"
      ></authenticator>

      <sign-out
        v-if="user"
        :signOutConfig="{ signOutButton: 'Logout' }"
      ></sign-out>
    </div>
  </div>
</template>

<script>
import { Hub } from "@aws-amplify/core"
import { createNamespacedHelpers } from "vuex"

import { SignOut } from "../components/auth"
import Authenticator from "../components/auth/Authenticator"
import AmplifyEventBus from "../auth/AmplifyEventBus"

const { mapActions, mapState } = createNamespacedHelpers("account")

export default {
  name: "Login",
  components: {
    Authenticator,
    SignOut
  },
  data() {
    return {
      authConfig: {
        signInConfig: {
          header: "Tuango Dashboard"
        },
        signUpConfig: {
          usernameAttributes: "email",
          hiddenDefaults: ["phone_number"],
          signUpFields: [
            { label: "AUTH.FIRST_NAME", key: "given_name", required: true },
            { label: "AUTH.LAST_NAME", key: "family_name", required: true }
          ]
        }
      },
      userName: ""
    }
  },
  computed: {
    ...mapState(["user"])
  },
  created: async function() {
    AmplifyEventBus.$on("authState", data => {
      console.log(data)
    })
    Hub.listen("auth", this.authListener)
  },
  beforeDestroy() {
    Hub.remove("auth", this.authListener)
  },
  methods: {
    ...mapActions(["checkSession", "endSession"]),
    authListener({ payload }) {
      const evt = payload.event
      switch (evt) {
        case "signIn":
          this.handleSignIn()
          break
        case "signUp":
          this.handleSignUp()
          break
        case "signOut":
          this.handleSignOut()
          break
        case "signIn_failure":
          this.handleAuthError()
          break
        case "configured":
          this.handleAuthConfigured()
          break
      }
    },
    async handleSignIn() {
      await this.checkSession()
      return this.$router.push({ name: "home" })
    },
    handleSignUp() {},
    handleSignOut() {
      this.endSession()
    },
    handleAuthError() {},
    handleAuthConfigured() {}
  }
}
</script>

<style lang="scss">
.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
