<template>
  <div class="page-wrapper">
    <main-header v-if="withHeader"></main-header>

    <div class="page-container">
      <sidebar v-if="withSidebar" ref="sidebar"></sidebar>

      <!-- BEGIN CONTENT -->
      <div class="page-content-wrapper">
        <!-- BEGIN CONTENT BODY -->
        <div class="page-content grey">
          <div class="page-bar">
            <breadcrumbs></breadcrumbs>
            <div class="page-toolbar"></div>
          </div>

          <slot></slot>
        </div>
        <!-- END CONTENT BODY -->
      </div>
      <!-- END CONTENT -->
    </div>
  </div>
</template>

<script>
/* ============
 * Default Layout
 * ============
 *
 * Used for the home and account pages.
 *
 * Layouts are used to store a lot of shared code.
 * This way the app stays clean.
 */

import MainHeader from "../components/UI/MainHeader"
import Sidebar from "../components/UI/Sidebar"
import Breadcrumbs from "../components/UI/Breadcrumbs"

export default {
  /**
   * The name of the layout.
   */
  name: "default-layout",
  props: {
    withHeader: {
      type: Boolean,
      default: true
    },
    withSidebar: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MainHeader,
    Sidebar,
    Breadcrumbs
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: 100vh;
}

.page-bar {
  position: relative;
  z-index: 1;
  background: #fff;
  top: -25px;
  margin: 0 -20px;
  padding: 5px 15px;
  color: #aaa;
  font-size: 1.5rem;
}

.page-content {
  &.grey {
    background: #eef1f5;
  }
}

.scroll-top {
  > i {
    display: inline-block;
    color: #687991;
    font-size: 30px;
    opacity: 0.6;
  }
}
</style>
