/* ============
 * VUEJS-LOGGER
 * ============
 *
 * Provides customizable logging functionality for Vue.js. Compatible with Vue2
 *
 * https://www.npmjs.com/package/vuejs-logger
 */

import Vue from "vue"
import VueLogger from "vuejs-logger"

const isProduction = process.env.NODE_ENV === "production"

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
}

Vue.use(VueLogger, options)
