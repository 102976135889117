import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./directives"
import "./filters"
import "./plugins"

import "./assets/scss/app.scss"
import i18n from "./i18n"

import breadcrumbs from "./mixins/breadcrumbs"

Vue.mixin(breadcrumbs)

Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
  window.LOG_LEVEL = "DEBUG"
}

store.dispatch("account/checkSession").then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#app")
})
