export default {
  getRefunds: (state, getters, rootState) => customerId => {
    const ordersWithRefunds = rootState.customer.orders.filter(order => {
      // Non-guest orders for given customer ID that have at least one refund.
      // note that one customer could have more than one
      // customer id per email (for different providers, ex.: facebook, google, DB)
      return customerId
        ? order => order.USER_ID === customerId && order.REFUNDS.length > 0
        : order.REFUNDS.length > 0
    })
    return ordersWithRefunds.reduce((refunds, order) => {
      order.REFUNDS.forEach(refund => {
        refunds.push(refund)
      })
      return refunds
    }, [])
  }
}
