<template>
  <div class="signout-form">
    <div>
      <div>{{ options.msg }}</div>
      <button class="signout-button" v-on:click="signOut">
        {{ options.signOutButton }}
      </button>
    </div>
    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import to from "await-to-js"

import AmplifyEventBus from "../../auth/AmplifyEventBus"
import { auth } from "../../auth/data-test-attributes"

export default {
  name: "SignOut",
  props: ["signOutConfig"],
  data() {
    return {
      error: "",
      show: false,
      auth,
      logger: {}
    }
  },
  computed: {
    options() {
      const defaults = {
        msg: null,
        signOutButton: this.$t("AUTH.SIGN_OUT")
      }
      return Object.assign(defaults, this.signOutConfig || {})
    }
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
  },
  methods: {
    async signOut() {
      const [err] = await to(this.$Amplify.Auth.signOut())

      if (err) {
        return this.setError(err)
      }

      this.logger.info("signout success")
      return AmplifyEventBus.$emit("authState", "signedOut")
    },
    setError: function(e) {
      this.error = this.$t(e.message || e)
      this.logger.error(this.error)
    }
  }
}
</script>

<style lang="scss" scoped>
.signout-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 3rem;
  background: #fff;

  .signout-button {
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #4285f4;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: lighten(#4285f4, 5%);
    }
  }

  .error {
    color: red;
  }
}
</style>
